<template>
<div class="full-width menus">
  <div class="container">
    <div class="col-10 g-c-flexo">
      <div class="col-2 footer-menu">
        <h6>ALTROMERCATO</h6>
        <a :href="$config.contentUrl + 'chi-siamo'" target="_BLANK">Chi siamo</a>
        <a :href="$config.contentUrl + 'produttori'" target="_BLANK">Produttori</a>
        <a :href="$config.contentUrl + 'store-locator'" target="_BLANK">Negozi</a>
      </div>
      <div class="col-2 footer-menu">
        <h6>AREA LEGALE</h6>
        <router-link to="/pages/condizioni-di-vendita">Condizioni di vendita</router-link>
        <router-link to="/pages/regolamento-gift-card">Regolamento Gift Card</router-link>
        <router-link to="/regolamento-promozioni">Regolamento promozioni</router-link>
        <router-link to="/pages/condizioni-uso">Condizioni d'uso</router-link>
        <router-link to="/pages/politica-di-privacy">Politica di Privacy</router-link>
        <router-link to="/pages/cookies">Cookies</router-link>
        <a @click="openCookieBar">Gestisci Cookie</a>
      </div>
      <div class="col-2 footer-menu">
        <h6>SERVIZIO CLIENTI</h6>
        <router-link to="/pages/metodi-di-pagamento">Metodi di pagamento</router-link>
        <router-link to="/pages/tempi-di-spedizione">Tempi di spedizione</router-link>
        <router-link to="/pages/risoluzione-controversie">Risoluzioni controversie</router-link>
        <router-link to="/pages/resi-e-rimborsi">Resi & Rimborsi</router-link>
        <router-link to="/faq">FAQ</router-link>
      </div>
      <div class="col-2 footer-menu">
        <h6>CONTATTI</h6>
        <a :href="$config.contentUrl + 'contatti/'" target="_BLANK">Servizio clienti</a>
      </div>
    </div>
    <div class="col-2">
      <div class="social-line">
        <a href="https://www.facebook.com/altromercato" target="_BLANK" class="social-button">
          <span class="fa fa-facebook-f"></span>
        </a>
        <a href="https://www.instagram.com/altromercato/" target="_BLANK" class="social-button">
          <span class="fa fa-instagram"></span>
        </a>
        <a href="https://twitter.com/altromercato" target="_BLANK" class="social-button">
          <span class="fa fa-twitter"></span>
        </a>
        <a href="https://www.youtube.com/altromercato" target="_BLANK" class="social-button">
          <span class="fa fa-youtube"></span>
        </a>
        <a href="https://it.linkedin.com/company/altromercato" target="_BLANK" class="social-button">
          <span class="fa fa-linkedin"></span>
        </a>
      </div>

      <img src="@/assets/certificazioni.png" class="certificazioni" />
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Menus',

  methods: {
    openCookieBar () {
      document.querySelector('[data-cky-tag="revisit-consent"]').click();
    }
  }
}
</script>

<style scoped>
h6 {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 600;
  letter-spacing: 0.4px;
  margin-bottom: 6px;
  font-size: 13px;
}

.footer-menu a {
  font-size: 12px;
  margin-bottom: 6px;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 400;
  letter-spacing: 0.5px;
  display: block;
}

.certificazioni {
  width: 170px;
  display: block;
  margin: 30px auto;
}

.social-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

@media (max-width: 680px) {
  .social-line {
    justify-content: center;
  }

  a.social-button {
    margin: 0 10px;
  }
}
</style>
