<template>
  <div id="app" v-if="!loading">
    <Header />
    <router-view  v-if="!loading"/>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  name: 'Root',
  metaInfo: {
    title: 'Homepage',
    titleTemplate: '%s | Altromercato Shop'
  },
  components: {
    Header,
    Footer
  },
  data: function () {
    return {
      loading: true
    }
  },

  async mounted () {

    this.live('click', 'v-toast__text', () => {
      this.$router.push('/cart')
    })

    await this.$utils.loadCategories()
    await this.$utils.loadFourthLevel()
    await this.$utils.loadProducts()
    await this.$utils.loadProduttori()
    this.loading = false
  },

  methods: {
    live (eventType, className, cb) {
      document.addEventListener(eventType, function (event) {
        if (event.target.classList.contains(className)) {
          cb.call(event.target, event);
        }
      });
    }
  }
}
</script>

<style>
</style>
