import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  state: {
    cart: [],
    cartExpiration: null,
    donation: 0,
    discount: false,
    user: {
      loggedIn: false,
      data: {}
    },
    gifts: []
  },
  mutations: {
    addToCart (state, data) {
      let newCart = []
      let newObj = true

      state.cart.forEach((itm) => {
        if (itm.item.itemNumber == data.item.itemNumber) {
          newCart.push({
            item: {
              ...itm.item
            },
            qty: parseInt(data.qty) + parseInt(itm.qty),
            gift: data.giftData,
            messages: data.messages
          })
          newObj = false
        } else {
          newCart.push(itm)
        }
      })

      if (newObj) {
        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
          event: "addToCart",
          ecommerce: {
            currencyCode: 'EUR',
            add: {
              products: [{
                name: data.item.productName,
                id: data.item.itemNumber,
                price: data.item.productPrice,
                brand: data.item.brand,
                category: data.item.categories[data.item.categories.length - 1].categoryName,
                variant: data.item.fairTradeSuppliers ? data.item.fairTradeSuppliers[0] : '',
                quantity: data.qty
              }]
            }
          }
        })

        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: "add_to_cart",
          ecommerce: {
            currency: "EUR",
            value: data.item.productPrice * data.qty,
            items: [
            {
              item_id: data.item.itemNumber,
              item_name: data.item.productName,
              currency: "EUR",
              item_brand: data.item.brand,
              item_category: data.item.categories[data.item.categories.length - 1].categoryName,
              item_variant: data.item.fairTradeSuppliers ? data.item.fairTradeSuppliers[0] : '',
              price: data.item.productPrice,
              quantity: data.qty
            }
            ]
          }
        });

        window._sbnaq.push(["trkProductBasketAdd", data.item.itemNumber]);

        if("fbq" in window) {
          window.fbq('track', 'AddToCart', {
            content_ids: [data.item.itemNumber],
            content_name: data.item.productName,
            content_type: 'product',
            value: data.item.productPrice,
            currency: 'EUR',
          });
        }

        newCart.push({
          item: data.item,
          qty: data.qty,
          gift: data.giftData,
          messages: data.messages
        })
      }

      state.cart = newCart
      state.cartExpiration = new Date().getTime() + 3 * 30 * 24 * 60 * 60 * 1000
    },
    checkIfExpired(state) {
      if(state.cartExpiration && state.cartExpiration < new Date().getTime()) {
        state.cart = []
      }
    },
    emptyCart (state) {
      state.cart = []
    },
    addGift (state, gifts) {
      state.gifts = gifts
    },
    deleteFromCart (state, id) {
      let data = state.cart[id]
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "removeFromCart",
        ecommerce: {
          remove: {
            products: [{
              name: data.item.productName,
              id: data.item.itemNumber,
              price: data.item.productPrice,
              brand: data.item.brand,
              category: data.item.categories[data.item.categories.length - 1].categoryName,
              variant: data.item.fairTradeSuppliers ? data.item.fairTradeSuppliers[0] : ''
            }]
          }
        }
      })

      window._sbnaq.push(["trkProductBasketRemove", data.item.itemNumber]);

      state.cart.splice(id, 1)
    },
    removeItem(state, itemNumber) {
      state.cart = state.cart.filter(entry => entry.item.itemNumber != itemNumber)
    },
    replaceItemInCart (state, data) {
      state.cart.forEach(entry => {
        if(entry.item.itemNumber == data.item.itemNumber) {
          entry.item = data.item
        }
      })
    },
    resetCart (state, data) {
      state.cart = data
    },
    login (state, data) {
      state.user.loggedIn = true
      state.user.data = data
      //state.discount = false
    },
    logout (state) {
      state.user.loggedIn = false
      state.user.data = {}
      state.discount = false
    },
    addAddress (state, data) {
      state.user.data = {
        ...state.user.data,
        ...data
      }
    },
    setCoupon(state, data) {
      state.discount = data
    },
    removeCoupon(state) {
      state.discount = false
    },
    setDonation(state, data) {
      state.donation = data
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [vuexLocal.plugin]
})
