<template>
<div class="full-width newsletter">
  <div class="container">
    <div class="col-5">
      <h2>Iscriviti alla Newsletter</h2>
    </div>
    <div class="col-5">
      <p>Diventa subito parte del cambiamento<br>e ottieni il 15% di sconto sul tuo primo acquisto</p>
    </div>
    <div class="col-2">
      <router-link to="/newsletter" class="button button-white">iscriviti</router-link>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Newsletter'
}
</script>

<style scoped>
.newsletter {
  background-color: #000;
  color: #fff;
  height: 85px;
}

.newsletter .container {
  align-items: center;
}

p {
  line-height: 140%;
  font-size: 19px;
}

@media (max-width: 680px) {
  .newsletter {
    height: auto;
    padding: 20px 10px;
    text-align: center;
  }

  .newsletter h2 {
    font-size: 28px;
  }

  .newsletter p {
    font-size: 18px;
  }
}
</style>
