<template>
<div class="full-width subf">
  <div class="container">
    <span class="sub-footer">ALTROMERCATO IMPRESA SOCIALE SOC. COOP. - SEDE LEGALE VIA F. CRISPI 9 - BOLZANO - P.IVA: IT01337600215</span>
  </div>
</div>
</template>

<script>
export default {
  name: 'Sub',
}
</script>

<style scoped>
.subf {
  height: 80px;
  background-color: #eee;
  line-height: 80px;
}

.sub-footer {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #000;
  font-size: 12px;
}

@media (max-width: 680px) {
  .subf {
    height: auto;
    line-height: 24px;
    padding: 20px 0;
    text-align: center;
  }
}
</style>
