<template>
<div>
  <div class="full-width hero-natale">
    <div class="col-12 banner-natale">
      <div class="container">
        <h1>Il regalo giusto è nelle tue scelte</h1>
      </div>
    </div>
  </div>

  <div class="full-width spacer free-shipping natale-text">
    <span>Scopri i nostri prodotti per decorare la tua casa, per dare valore ai piatti che porti in tavola e per regalare un Natale più giusto con le tue scelte.</span>
  </div>

  <div class="full-width bottoni-natale">
    <div class="button" @click="scrollMeTo('tavola')" style="background-color: #ee8a9d">Decora le tue feste</div>
    <div class="button" @click="scrollMeTo('idee')" style="background-color: #f08025">Il Natale in tavola</div>
    <div class="button" @click="scrollMeTo('decora')" style="background-color: #257440">Idee regalo</div>
    <div class="button" @click="scrollMeTo('regalistica')" style="background-color: #50c1ea">Arrivano i buoni</div>
  </div>

  <div class="full-width big-banner-natale banner-decora" ref="tavola">
    <div class="container big-banner-caffe big-banner">
      <div class="col-6 center-content">
        <p class="big-p"><b>Decora le tue feste</b></p>
        <p>Lasciati ispirare dalle novità di presepi e addobbi artigianali multicolore, design originale e materiali che rispettano l'ambiente.</p>
        <router-link to="/category/EC73300/natale" class="button" style="background-color: #ee8a9d">Scopri tutte le decorazioni</router-link>
      </div>
    </div>
  </div>

  <div class="full-width gray-spacer no-mobi"></div>

  <div class="full-width big-banner-natale" v-if="vetrina1.length">
    <div class="container">
      <div class="col-6 big-natale-img">
        <div class="prod">
          <img :src="'https://shop.altromercato.it/products/' + vetrina1[0].images[0] + '?v=2'" />
          <router-link :to="'/product/' + vetrina1[0].itemNumber + '/' + $utils.getSlug(vetrina1[0].productName)" class="onhover">{{vetrina1[0].productName}}</router-link>
          <div class="add-to-cart" @click="addToCart(vetrina1[0])">
            <img src="@/assets/cart-white.png" class="cart-icon" />
          </div>
        </div>
      </div>
      <div class="col-6 little-natale-img">
        <div v-for="key in [1,2,3,4]" v-bind:key="key" class="prod">
          <img :src="'https://shop.altromercato.it/products/' + vetrina1[key].images[0] + '?v=2'" v-if="vetrina1[key]" />
          <router-link :to="'/product/' + vetrina1[key].itemNumber + '/' + $utils.getSlug(vetrina1[key].productName)" v-if="vetrina1[key]" class="onhover">
            {{vetrina1[key].productName}}
          </router-link>
          <div class="add-to-cart" @click="addToCart(vetrina1[key])" v-if="vetrina1[key]">
            <img src="@/assets/cart-white.png" class="cart-icon" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="full-width gray-spacer no-mobi"></div>

  <div class="full-width big-banner-natale banner-tavola" ref="idee">
    <div class="container big-banner-caffe big-banner">
      <div class="col-6 center-content">
        <p class="big-p"><b>Il Natale in tavola</b></p>
        <p>Tanta dolcezza da ingredienti etici, da servire in tavola o regalare per condividere i sapori di un Natale da commercio giusto.</p>
        <router-link to="/category/EC71700/natale" class="button" style="background-color: #f08025">Vai alle proposte</router-link>
      </div>
    </div>
  </div>

  <div class="full-width gray-spacer no-mobi"></div>

  <div class="full-width big-banner-natale" v-if="vetrina2.length">
    <div class="container">
      <div class="col-6 big-natale-img">
        <div class="prod">
          <img :src="'https://shop.altromercato.it/products/' + vetrina2[0].images[0] + '?v=2'" />
          <router-link :to="'/product/' + vetrina2[0].itemNumber + '/' + $utils.getSlug(vetrina2[0].productName)" class="onhover">{{vetrina2[0].productName}}</router-link>
          <div class="add-to-cart" @click="addToCart(vetrina2[0])">
            <img src="@/assets/cart-white.png" class="cart-icon" />
          </div>
        </div>
      </div>
      <div class="col-6 little-natale-img">
        <div v-for="key in [1,2,3,4]" v-bind:key="key" class="prod">
          <img :src="'https://shop.altromercato.it/products/' + vetrina2[key].images[0] + '?v=2'" v-if="vetrina2[key]" />
          <router-link :to="'/product/' + vetrina2[key].itemNumber + '/' + $utils.getSlug(vetrina2[key].productName)" v-if="vetrina2[key]" class="onhover">
            {{vetrina2[key].productName}}
          </router-link>
          <div class="add-to-cart" @click="addToCart(vetrina2[key])" v-if="vetrina2[key]">
            <img src="@/assets/cart-white.png" class="cart-icon" />
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="full-width gray-spacer no-mobi"></div>

  <div class="full-width big-banner-natale banner-idee" ref="decora">
    <div class="container big-banner-caffe big-banner">
      <div class="col-6 center-content">
        <p class="big-p"><b>Idee regalo</b></p>
        <p>Quest'anno scegli dei regali d'impatto, con prodotti etici, per festività ad alto impatto sociale e basso impatto ambientale</p>
        <router-link to="/category/EC67000/idee-regalo" class="button" style="background-color: #257440">Vai alle idee regalo</router-link>
      </div>
    </div>
  </div>

  <ProductSlider code="EH00004" :order="true" />

  <div class="full-width big-banner-natale banner-regalistica" ref="regalistica">
    <div class="container big-banner-caffe big-banner">
      <div class="col-6 center-content">
        <p class="big-p"><b>Arrivano i buoni</b></p>
        <p>Torna a trovarci anche a Dicembre, ti aspetta una promo!</p>
        <!-- <a href="https://www.altromercato.it/wp-content/uploads/2023/11/Regolamento_BUONI_23.pdf" target="_blank" class="button" style="background-color: #50c1ea">Leggi il regolamento</a> -->
      </div>
    </div>
  </div>
  <div class="full-width gray-spacer no-mobi"></div>
  <div class="full-width spacer no-mobi"></div>

  <div class="full-width">
    <div class="container m-am">
      <h3 class="neue">Mondo Altromercato</h3>
    </div>
  </div>

  <div class="full-width mondo-altromercato">
    <div class="container">
      <div class="external-link-image">
        <img src="@/assets/external/produttori.png" />
        <a :href="$config.contentUrl + 'produttori/'" class="neue">
          Produttori
          <img src="@/assets/arrow.png" />
        </a>
      </div>

      <div class="external-link-image">
        <img src="@/assets/external/negozi.png" />
        <a :href="$config.contentUrl + 'i-negozi-altromercato/'" class="neue">
          Negozi
          <img src="@/assets/arrow.png" />
        </a>
      </div>

      <div class="external-link-image">
        <img src="@/assets/external/sostenibilita.png" />
        <a :href="$config.contentUrl + 'sostenibilita-agita/'" class="neue">
          Sostenibilit&agrave;
          <img src="@/assets/arrow.png" />
        </a>
      </div>
    </div>
  </div>
  <div class="full-width spacer no-mobi"></div>
</div>
</template>

<script>
import ProductSlider from '@/components/ProductSlider'

export default {
  name: 'Home',
  components: {
    ProductSlider
  },

  data: function () {
    return {
      configuration: '',
      vetrina1: [],
      vetrina2: [],
    }
  },

  async created () {
    this.vetrina1 = await this.$utils.getCategoryOrder('EH00002')
    this.vetrina2 = await this.$utils.getCategoryOrder('EH00003')

  },

  methods: {
    scrollMeTo(refName) {
      let element = this.$refs[refName];
      let top = element.offsetTop - 150;

      window.scrollTo(0, top);
    },
    addToCart(item) {
      this.$toast.open('Articolo aggiunto al carrello')
      this.$store.commit('addToCart', {
        item,
        qty: item.multiplo ? item.multiplo : 1
      })
    }
  }
}
</script>

<style scoped>
.banner-natale {
  background-size: cover;
  background-repeat: no-repeat;
}

body .banner-natale h1 {
  max-width: 500px;
  color: #fff;
  text-align: left;
  display: flex;
  align-items: center;
  height: 450px;
  font-family: neue-haas-grotesk-display, sans-serif;
}

.banner-natale .container {
  margin: 0 auto;
}

.big-banner-natale {
  background-color: #eee !important;
}

.big-banner-natale .container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.free-shipping.natale-text {
  padding: 20px 50px;
  line-height: 150%;
  height: auto;
}

.bottoni-natale {
  background-color: #eee;
  padding: 20px 0;
}

.bottoni-natale .button {
  margin: 0 20px !important;
}

.big-natale-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.little-natale-img {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 0 0 10px;
}

.big-natale-img .prod,
.little-natale-img .prod {
  position: relative;
  background-color: #fff;
}

.little-natale-img .prod {
  width: 49%;
}

.little-natale-img .prod > img {
  width: 100%;
}

.big-natale-img .prod a,
.little-natale-img .prod a {
  position: absolute;
  color: #000;
  font-size: 24px;
  transition: all 0.3s linear;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  background-color: rgba(255,255,255,0.5);
  backdrop-filter: blur(8px)!important;
  justify-content: space-around;
  padding: 0 15px;
}

.add-to-cart {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 200px;
}

.add-to-cart img.cart-icon {
  width: 20px !important;
  height: auto !important;
}

.little-natale-img .add-to-cart,
.big-natale-img .add-to-cart {
  width: auto !important;
  padding: 0 10px 0 3px !important;
}

.little-natale-img .prod a {
  font-size: 18px;
  min-height: 50px;
  line-height: 20px;
}

.big-natale-img .prod:hover a,
.little-natale-img .prod:hover a {
  opacity: 1;
}

.little-natale-img img {
  width: 49%;
}

.external-link-image a img {
  transform: rotate(180deg);
  margin-left: 5px;
}

@media(max-width:768px){
  .slick-next {
    right: 40px;
  }
  .slick-prev {
      left: 40px;
      z-index: 1;
  }
  .little-home {
    font-size: 18px;
    line-height: 140%;
    max-width: 80%;
  }

  .banner-natale h1 {
    width: 90% !important;
    text-align: center !important;
    margin: 0 auto !important;
    line-height: 1.5em;
  }

  .bottoni-natale .button {
    margin-bottom: 15px !important;
    text-align: center !important;
  }

  .big-banner p {
    text-align: center !important;
    margin-bottom: 25px !important;
  }

  .big-banner .big-p {
    font-size: 32px !important;
  }

  .big-banner .button {
    width: 90% !important;
    text-align: center !important;
    padding: 0 !important;
  }

  .little-natale-img {
    padding: 0 !important;
  }

  .little-natale-img .prod {
    width: 100% !important;
    border-bottom: 1px solid #ccc;
  }

  .big-natale-img {
    /*display: none !important;*/
    border-bottom: 1px solid #ccc;
  }
}

@media (max-width:680px) {
  .hero .button{
    font-size: 14px;
  }
  .hero p{
    margin-top: 15px!important;
  }
}
</style>
