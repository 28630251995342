<template>
<div>
  <div class="menu-placeholder"></div>
  <div class="fixed-menu">
    <Sup />
    <Menu />
  </div>
</div>
</template>

<script>
import Sup from './header/Sup'
import Menu from './header/Menu'

export default {
  name: 'Header',

  components: {
    Sup,
    Menu
  }
}
</script>

<style>
.fixed-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.menu-placeholder {
  width: 100%;
  height: 120px;
}
</style>
