<template>
<div>
  <div class="full-width gray-spacer"></div>
  <div class="full-width gray-bg">
    <div class="container" style="display: block;">

      <VueSlickCarousel v-bind="settings" :arrows="true" v-if="products.length">
        <div class="product-outer" v-for="(item, k) in products" v-bind:key="k">
          <div class="product-card">
            <div class="marks">
              <img src="@/assets/marks/icona1.png" v-if="item.tags.includes('ORGANIC')" v-tooltip="'BIO'" />
              <img src="@/assets/marks/icona2.png" v-if="item.tags.includes('VEGAN')" v-tooltip="'VEGAN'" />
              <img src="@/assets/marks/icona3.png" v-if="item.tags.includes('GLUTEN_FREE')" v-tooltip="'GLUTEN FREE'" />
            </div>

            <router-link :to="'/product/' + item.itemNumber + '/' + $utils.getSlug(item.productName)">
              <div v-if="!$utils.isModa(item.itemNumber) || (item.images && item.images.length < 2)">
                <img :src="'https://shop.altromercato.it/products/' + item.images[0]" class="product-image" v-if="item.images && item.images[0]" />
                <img src="https://shop.altromercato.it/products/placeholder.png" class="product-image" v-if="!item.images" />
              </div>

              <div v-if="$utils.isModa(item.itemNumber) && item.images && item.images.length > 1" class="hover-img">
                <img :src="'https://shop.altromercato.it/products/' + item.images[0]" class="product-image front" />
                <img :src="'https://shop.altromercato.it/products/' + item.images[1]" class="product-image back" />
              </div>
            </router-link>

            <div class="product-content">
              <h2>{{item.productName}}</h2>

              <div class="discount" v-if="item.sconto > 0 && item.stock > 0 && item.acquistabile">
                <span class="old-price">&euro; {{item.productPrice.toFixed(2)}}</span>
                <span class="minus">-{{item.sconto}}%</span>
              </div>

              <div style="height: 32px" v-if="item.sconto == 0 || item.stock < 1 || !item.acquistabile"></div>

              <div class="row" v-if="item.stock > 0 && item.acquistabile">
                <div class="price col-6">
                  <span class="current">&euro; {{$utils.getProductPrice(item).toFixed(2)}}</span>
                  <span class="online-price">Prezzo online</span>
                </div>
                <div class="col-6">
                  <div class="add-to-cart" @click="addToCart(item)">
                    aggiungi
                    <img src="@/assets/cart-white.png" class="cart-icon" />
                    <!--<span class="fa fa-shopping-cart"></span>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>

    </div>
  </div>

  <div class="full-width gray-spacer"></div>
</div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'ProductSlider',
  data: function () {
    return {
      products: [],
      settings: {
        "dots": true,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 4,
        "slidesToScroll": 4,
        "swipeToSlide": false,
        "touchThreshold": 2,
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]
      }
    }
  },

  components: {
    VueSlickCarousel
  },

  props: ['code', 'exclude', 'supplier', 'related', 'order', 'maxProducts'],

  async created () {
    if (this.supplier) {
      this.products = this.$utils.getRandomFromSupplierCode(this.supplier)
      return true
    }

    if (this.order) {
      this.products = await this.$utils.getCategoryOrder(this.code)
    } else {

      if (this.code != 'VETRINA') {
        this.products = this.$utils.getRandomFromCatCode(this.code, this.exclude, this.$utils.getRelated(this.related), this.maxProducts)
      } else {
        this.products = this.$utils.getProductsVetrina()
      }
    }

  },

  methods: {
    addToCart (item) {
      this.$store.commit('addToCart', {
        item,
        qty: 1
      })

      this.$toast.open('Articolo aggiunto al carrello')
    }
  }
}
</script>

<style scoped>
.product-outer {
  padding: 0 5px;
}

.product-card {
  width: 285px;
  min-height: 442px;
  padding: 20px 0;
  background-color: #fff;
  position: relative;
}

.product-card .promo {
  position: absolute;
  top: 16px;
  right: 0;
  padding: 0 6px;
  height: 22px;
  line-height: 24px;
  color: #fff;
  background-color: #f09049;
  font-weight: 800;
  font-size: 14px;
}

.product-image {
  width: 235px;
  height: 235px;
  object-fit: cover;
  display: block;
  margin: 0 auto 48px auto;
}

.marks {
  position: absolute;
  top: 16px;
  left: 10px;
  width: 32px;
}

.marks img {
  width: 32px;
  height: auto;
  margin-bottom: 3px;
}

.product-content {
  padding: 0 22px;
}

.product-content h2 {
  font-size: 17px;
  line-height: 130%;
  font-weight: 700;
  font-family: neue-haas-grotesk-display, sans-serif;
  margin: 10px 0 0 0;
  min-height: 66px;
}

.product-card .discount {
  position: relative;
  left: -10px;
  padding: 0 6px;
  height: 22px;
  line-height: 22px;
  color: #fff;
  background-color: #f09049;
  /*background-color: #fff;*/
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  font-family: neue-haas-grotesk-display, sans-serif;
  margin: 5px 0;
}

.product-card .discount .old-price {
  text-decoration: line-through;
  display: inline-block;
  margin-right: 6px;
}

.product-card .price .current {
  display: block;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  line-height: 100%;
}

.product-card .price .online-price {
  display: block;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  letter-spacing: 0.4px;
}

.hover-img .back {
  display: none;
}

.hover-img:hover .back {
  display: block;
}

.hover-img:hover .front {
  display: none;
}

@media (max-width: 680px) {
  .product-card {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
