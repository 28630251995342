import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import utils from './utils.js'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import catDescs from './mockups/categoriesDescr'
import config from './config'
import VTooltip from 'v-tooltip'
import province from './mockups/province'
import caps from './mockups/caps'
import VModal from 'vue-js-modal/dist/index.nocss.js'
import 'vue-js-modal/dist/styles.css'
import VueMeta from 'vue-meta'

Vue.use(VTooltip)
Vue.use(VueToast)
Vue.use(VModal)
Vue.use(VueMeta)

Vue.prototype.$utils = utils
Vue.prototype.$cats = catDescs
Vue.prototype.$config = config
Vue.prototype.$province = province
Vue.prototype.$caps = caps
Vue.prototype.$viewCurrentIndex = {}

Vue.config.productionTip = false
require('@/assets/main.css')

console.log(config.backendUrl)

router.afterEach(() => store.commit('checkIfExpired'))

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
