export default [
  {
    code: 'EC61000',
    descr: 'Tanti prodotti per mangiare e bere il meglio del mondo. Alimentari e bevande da filiera equosolidale e sostenibile, realizzati pensando al pianeta, alle persone e ai tuoi momenti di gusto.'
  },
  {
    code: 'EC71000',
    descr: 'I nostri caffè, orzo, tè, infusi e tisane equosolidali sono il frutto delle migliori origini al mondo. Vengono coltivati con metodi attenti all’ambiente e lavorati nel rispetto delle persone. Cambia il mondo a ogni sorso.'
  },
  {
    code: 'EC71100',
    descr: 'Simboli di indipendenza e riscatto, il nostro zucchero di canna integrale e lo zucchero di canna grezzo, biologici e non raffinati, sono realizzati in modo artigianale da agricoltori liberi dallo sfruttamento.'
  },
  {
    code: 'EC71200',
    descr: 'Mascao, Compañera, Bribon, Nocciolato, cacao magro in polvere, Cajita spalmabile e tanti altri. In ognuno di loro ritrovi più di 30 anni di esperienza nel mondo del cacao e del cioccolato equosolidali.'
  },
  {
    code: 'EC71300',
    descr: 'Un piccolo momento quotidiano può dare vita ad un vero cambiamento. Risveglia questa consapevolezza con frutta secca bio, mieli, marmellate e composte delle nostre filiere sostenibili o scegli tra frollini, merendine, snack salati e muesli che uniscono ingredienti realizzati nel rispetto di tutti.'
  },
  {
    code: 'EC71400',
    descr: 'Spezie dal mondo che danno voce a piccoli produttori, salse e sughi liberi dal caporalato, farine, pasta di grano e pasta di quinoa, riso etnico e legumi che esaltano le tue ricette rispettando la terra. Porta subito nella tua cucina un altro sapore.'
  },
  {
    code: 'EC71500',
    descr: 'Hai sete di cambiamento? Dissetati con bevande gassate fresche e naturali, birra artigianale e birra agricola, vino tipico della Sicilia, spumante biologico della Franciacorta, prosecco bio del Valdobbiadene e tante altre bontà da bere, tutte realizzate da realtà etiche e sostenibili.'
  },
  {
    code: 'EC71600',
    descr: 'I nostri integratori alimentari equosolidali sono pensati per sfruttare appieno il potere della natura: ad ognuno un compito preciso per sostenerti durante la giornata. Trova quello giusto per te.'
  },
  {
    code: 'EC62000',
    descr: 'Con Natyr abbiamo realizzato una cosmesi equa e solidale, ecocompatibile, innovativa ma rispettosa delle tradizioni del mondo. Ora tocca a te sceglierla.'
  },
  {
    code: 'EC72000',
    descr: 'Realizza il tuo benessere sostenibile curando l’igiene quotidiana di corpo e viso con saponi e bagnodoccia equosolidali, giusti per la tua pelle e per tutti.'
  },
  {
    code: 'EC72100',
    descr: 'Shampoo, balsamo e maschere per capelli, realizzati con ingredienti naturali e delicati, pensati per la cura e il nutrimento di tutti i tipi di capello. Scegli una vera bellezza sostenibile.'
  },
  {
    code: 'EC72200',
    descr: 'Creme per la cura del viso, tonici bio, detergente viso naturale e tanti trattamenti completi per assicurarti la giusta idratazione, freschezza e detergenza di giorno e di notte.'
  },
  {
    code: 'EC72300',
    descr: 'Prodotti corpo naturali a base di oli essenziali naturali e burri vegetali ispirati a tradizioni millenarie di bellezza e realizzati con formule innovative.'
  },
  {
    code: 'EC72400',
    descr: 'Prodotti cosmetici per l’uomo che uniscono le note agrumate tipiche dell’olio essenziale di bergamotto alle proprietà antiossidanti e purificanti del tè nero, per un’esperienza di benessere senza confini.'
  },
  {
    code: 'EC72600',
    descr: 'I nostri oli essenziali puri per l’aromaterapia vengono realizzati con estratti di ingredienti naturali e sono pensati per ritrovare il benessere del corpo in armonia con il mondo.'
  },
  {
    code: 'EC72500',
    descr: 'Cofanetti e prodotti cosmetici biologici ed equosolidali Natyr ideati per regalare un’autentica bellezza sostenibile.'
  },
  {
    code: 'EC63000',
    descr: 'Arreda in modo sostenibile, decora la tavola e profuma gli ambienti che vivi con le creazioni artigianali On Earth - ethical home. Esprimi la tua voglia di cambiamento cominciando dallo spazio che più di tutti riflette le tue scelte: la tua casa.'
  },
  {
    code: 'EC73000',
    descr: 'Crea l’ambiente più giusto per la tua casa con la nostra selezione di vasi e arazzi artigianali, candele profumate e incensi naturali.'
  },
  {
    code: 'EC73100',
    descr: 'Mug, tazzine da caffè, teiere, vassoi e tutto quello di cui hai bisogno per prepara una tavola piena di gusto e valore.'
  },
  {
    code: 'EC73200',
    descr: 'Tappeti etnici e stuoie, tovaglie e tovagliette, qui trovi tutte i tessili per la casa On Earth – ethical che abbiamo pensato per te.'
  },
  {
    code: 'EC64000',
    descr: 'Le nostre bomboniere equosolidali sono pensate per condividere la gioia dei tuoi momenti speciali con il mondo. Realizzate in maniera artigianale e sostenibile, sono oggetti utili, pieni di significato e di valore per te che le scegli, per chi le riceve e per chi le realizza.'
  },
  {
    code: 'EC74000',
    descr: 'Con le nostre bomboniere equosolidali per il matrimonio, fai una scelta che vale di più. Sono bomboniere artigianali, funzionali e sostenibili per l’ambiente e per le persone che le creano.'
  },
  {
    code: 'EC74100',
    descr: 'Per la nascita e il battesimo abbiamo pensato a tante proposte bomboniera equosolidali colorate, decorate o tessute che raccontino la bellezza di una nuova vita.'
  },
  {
    code: 'EC74200',
    descr: 'Le nostre bomboniere equosolidale per la comunione e la cresima sono creazioni uniche e irripetibili. Scegliendole sostieni realtà che realizzano un futuro sostenibile per tutti.'
  },
  {
    code: 'EC74300',
    descr: 'Le nostre bomboniere equosolidali per la laurea sono perfette per celebrare questo importante traguardo nel modo più giusto per tutti e agire per cambiare il mondo con le proprie scelte.'
  },
  {
    code: 'EC74400',
    descr: 'Celebra le tue occasioni speciali con i nostri confetti equosolidali, piccole delizie di zucchero di canna, mandorle e cioccolato provenienti da piccoli produttori delle nostre filiere etiche e sostenibili.'
  },
  {
    code: 'EC65000',
    descr: 'La moda etica e solidale di On Earth rispetta le stagioni, le persone e il pianeta. Ogni vestito, gioiello o accessorio è realizzato con materiali di alta qualità lavorati in progetti di filiera etica e sostenibile con l’obiettivo di creare una moda che metta al centro il benessere di tutti.'
  },
  {
    code: 'EC75000',
    descr: 'Tessuti preziosi lavorati in modo artigianale diventano capi unici che uniscono tradizioni lontane e design italiano, etica ed estetica, per donarti una moda a misura di tutti.'
  },
  {
    code: 'EC75100',
    descr: 'Gli accessori On Earth sono pensati per ogni occasione e per ogni stagione: borse, portafogli, stole, foulard, tutti lavorati artigianalmente, tutti prodotti con cura delle persone e della Terra.'
  },
  {
    code: 'EC75200',
    descr: 'Artigianato di alta qualità, materiali naturali e ricerca nel design caratterizzano anelli, bracciali, collane e orecchine On Earth. Una collezione di gioielli di grande valore, realizzata da realtà che uniscono creatività, salvaguardia della cultura locale, sostenibilità ambientale e sociale.'
  },
  {
    code: 'EC66000',
    descr: 'Comprare etico conviene a tutti: trovi qui le migliori occasioni per i tuoi acquisti equosolidali online.'
  },
  {
    code: 'EC76200',
    descr: 'La tua occasione per scegliere la nostra moda etica On Earth.'
  },
  {
    code: 'EC76000',
    descr: 'Per le tue occasioni importanti, scegli le nostre bomboniere equosolidali in occasione.'
  },
  {
    code: 'EC76100',
    descr: 'La nostra selezione On Earth – ethical per vivere la tua casa nel modo più conveniente per te e sostenibile per tutti.'
  },
  {
    code: 'EC77000',
    descr: 'Un regalo equosolidale vale di più. Scopri la nostra selezione di idee regalo equosolidali e sostenibili.'
  },
  {
    code: 'EC87008',
    descr: 'Scegli le nostre gift card per regalare in ogni occasione un mondo migliore.'
  }
]
