<template>
<div id="footer">
  <Newsletter />
  <Menus />
  <Sub />
</div>
</template>

<script>
import Newsletter from './footer/Newsletter'
import Menus from './footer/Menus'
import Sub from './footer/Sub'

export default {
  name: 'Footer',
  components: {
    Newsletter,
    Menus,
    Sub
  }
}
</script>

<style>

</style>
