<template>
  <div class="full-width">
    <div class="container column" style="padding: 40px 0; min-height: 40vh;">
      <table>
        <tr>
          <td style="width: 50%;padding-right: 20px;padding-bottom: 15px;">
            <!-- <strong>Regolamento Operazione a Premio Latta Zucchero</strong> -->
            <em>Regolamento Operazione a Premio Latta Zucchero (scaduta)</em>
          </td>
          <td style="width: 50%;padding-bottom: 15px;">
            <a href="/regolamenti/REGOLAMENTO_ALTROMERCATO_ZUCCHERO.pdf" target="_blank" style="font-size:1em;text-decoration: underline;font-weight: normal;">Scarica ora</a>
          </td>
        </tr>
        <tr>
          <td style="width: 50%;padding-right: 20px;padding-bottom: 15px;">
            <em>Regolamento Operazione a Premio "Primavera Cosmetica" (scaduta)</em>
          </td>
          <td style="width: 50%;padding-bottom: 15px;">
            <a href="/regolamenti/REGOLAMENTO_ALTROMERCATO_primavera_cosmetica.pdf" target="_blank" style="font-size:1em;text-decoration: underline;font-weight: normal;">Scarica ora</a>
          </td>
        </tr>
        <tr>
          <td style="width: 50%;padding-right: 20px;padding-bottom: 15px;">
            <em>Regolamento Operazione a Premio "Caffè Altromercato" (scaduta)</em>
          </td>
          <td style="width: 50%;padding-bottom: 15px;">
            <a href="/regolamenti/REGOLAMENTO_ALTROMERCATO_caffe.pdf" target="_blank" style="font-size:1em;text-decoration: underline;font-weight: normal;">Scarica ora</a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegolamentoPromozioni',
  metaInfo () {
    this.$utils.adabraPageView(this, { pageType: 108, pageTitle: 'Regolamento promozioni' });
    return {
      title: 'Regolamento promozioni'
    }
  }
}
</script>

<style scoped>
.container.column {
  flex-direction: column;
}
</style>
